<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
export default {
  name: 'specialtyGoods',
  data() {
    return api.command.getState()
  },

  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      api.command.getList.call(this, {
        url: '/farmSyncNew/findTenantList',
        current: 1
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'tenantName',
          type: 'input'
        },
        {
          label: '',
          type: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'tenantName',
          title: '租户',
          align: 'left'
        },
        {
          dataIndex: 'tbz',
          title: '正在同步',
          align: 'left'
        },
        {
          dataIndex: 'tbsb',
          title: '同步失败',
          align: 'left'
        },
        {
          dataIndex: 'allNum',
          title: '已同步商品',
          align: 'left'
        },
        {
          dataIndex: 'diffNum',
          title: '同步后有差异',
          align: 'left'
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '立即更新',
                onClick: () => {
                  getAction('/farmSyncNew/syncTenantProduct?syncTenantId=' + records.tenantId).then(res => {
                    this.$message.success(res.data || '操作成功！')
                    this.initData()
                  })
                }
              },
              {
                name: '同步列表',
                onClick: () => {
                  this.$router.push('/commodityManagement/specialtyGoodsSynchronous?syncTenantId=' + records.tenantId)
                }
              }
            ]
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        showPagination={true}
        button={[]}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        records={this.records}
      />
    )
  }
}
</script>
